<template>
  <div>
    <header>
      <img v-bind:src="groupImage" style="width: 50px; height: 50px" />
      <div style="max-width: 65%; margin-right: 0">
        <h2>{{ this.groupName }}</h2>
        <div v-if="group.tags" style="margin-right: 0">
          <span
            v-for="tag in group.tags"
            :key="tag.id"
            style="
              margin-right: 5px;
              font-size: 13px;
              font-weight: 500;
              background: blueviolet;
              border-radius: 5px;
              color: white;
            "
            >#{{ tag.name }}</span
          >
        </div>
        <div
          v-if="programme"
          style="margin-right: 5px; font-size: 13px; font-weight: 500"
        >
          Chương trình: {{ programme }}
        </div>
      </div>
      <div style="margin-right: 0; float: right">
        <button
          v-if="Object.keys(group.members).length == 1"
          v-on:click="showModalAssignCs()"
          style="
            line-height: 30px;
            padding: 5px;
            margin-right: 15px;
            text-decoration: none;
            background: #377dff;
            vertical-align: top;
            color: white;
            border-radius: 10px;
            -webkit-box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
            box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
          "
        >
          Gắn CS
        </button>
        <button
          v-on:click="showModalTag()"
          style="
            line-height: 30px;
            padding: 5px;
            margin-right: 15px;
            text-decoration: none;
            background: #377dff;
            vertical-align: top;
            color: white;
            border-radius: 10px;
            -webkit-box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
            box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
          "
        >
          Gắn tag
        </button>
        <button
          style="
            line-height: 30px;
            padding: 5px;
            margin-right: 15px;
            text-decoration: none;
            background: #377dff;
            vertical-align: top;
            color: white;
            border-radius: 10px;
            -webkit-box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
            box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
          "
          v-on:click="closeGroup(group.id)"
        >
          Đóng hội thoại
        </button>
      </div>
    </header>
    <ul
      id="chat"
      class="blockChat"
      style="height: 75.5vh"
      v-chat-scroll="{ always: false }"
      @v-chat-scroll-top-reached="loadMore"
    >
      <li
        v-for="(contentMessage, index) in listMessage"
        :key="index"
        v-bind:class="{
          me:
            (contentMessage.sentBy == prepUser.uid && !isThirdView) ||
            (isThirdView && contentMessage.sentBy != group.createdBy),
          you:
            (contentMessage.sentBy != prepUser.uid && !isThirdView) ||
            (isThirdView && contentMessage.sentBy == group.createdBy),
        }"
      >
        <div v-if="contentMessage.sentBy != 0">
          <div
            class="entete"
            v-if="contentMessage.sentBy != prepUser.uid && !isThirdView"
          >
            <span class="status green"></span>
            <h2>{{ groupName }}</h2>
            <h3>({{ contentMessage.sentAt.seconds | formatUnix }})</h3>
          </div>

          <div
            class="entete"
            v-if="contentMessage.sentBy == prepUser.uid && !isThirdView"
          >
            <h3>{{ contentMessage.sentAt.seconds | formatUnix }}</h3>
            <h2>{{ prepUser.fullname }}</h2>
            <span class="status blue"></span>
          </div>

          <div class="entete" v-if="isThirdView">
            <h3>{{ contentMessage.sentAt.seconds | formatUnix }}</h3>
            <h2>&nbsp;{{ getUserById(contentMessage.sentBy).displayName }}</h2>
            <span class="status blue"></span>
          </div>
          <div class="triangle"></div>
          <div class="message">
            <div
              v-if="contentMessage.type == 'text'"
              v-html="contentMessage.messageText"
            ></div>
            <div v-if="contentMessage.type == 'image'" class="message-image">
              <img
                v-for="(imageSrc, indexImage) in contentMessage.messageText"
                :key="indexImage"
                v-bind:src="imageSrc"
                v-on:click="openPreview(imageSrc)"
              />
            </div>
            <div
              v-if="contentMessage.type == 'tag-marking'"
              class="message-tag-marking"
            >
              <span>#Hỏi đáp chấm bài</span>
              <p>
                Course:
                <a
                  :href="tagMarkingLink(contentMessage.messageText)"
                  target="_blank"
                  >{{ tagMarkingName(contentMessage.messageText) }}</a
                >
              </p>
              <p
                v-if="
                  tagMarkingLesson(contentMessage.messageText) != 'undefined'
                "
              >
                Lesson: {{ tagMarkingLesson(contentMessage.messageText) }}
              </p>
              <p
                v-if="
                  tagMarkingTicketID(contentMessage.messageText) != 'undefined'
                "
              >
                Ticket ID: #{{ tagMarkingTicketID(contentMessage.messageText) }}
              </p>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <!-- <footer class="block-chat-footer">
            <textarea
                placeholder="Nhập nội dung"
                v-model="group.text"
                v-on:keyup.enter="handleEventSendMessage(group, $event)"
            ></textarea>

            <input type="hidden" v-model="group.contentType" value="text" />
            <img v-bind:src="require('../../assets/ico_picture.png')"
                v-on:click="openSelectImage()">
            <input type="file" hidden
                    class="input-file-hidden"
                    ref="inputfilehidden"
                    @change="onFileChange"
                    multiple
                    accept="image/png, image/gif, image/jpeg">
			<a href="#" v-on:click="sendMessage(group)">Gửi</a>
		</footer> -->
    <Preview :url="urlPreview" @hidePreview="hidePreview"></Preview>
    <div class="modal" v-bind:class="{ 'modal-show': showModal }">
      <!-- Modal content -->
      <div class="modal-content">
        <span class="close" v-on:click="hideModalTag()">&times;</span>
        <div>
          <h5>Gán tag cho hội thoại</h5>
          <multiselect
            v-model="group.tags"
            placeholder="Tìm kiếm"
            label="name"
            track-by="id"
            :options="this.$store.state.prepChatGroup.tags"
            :multiple="true"
            :taggable="true"
          ></multiselect>
          <hr />
          <button
            style="
              line-height: 30px;
              padding: 5px;
              margin-right: 15px;
              text-decoration: none;
              background: #377dff;
              vertical-align: top;
              color: white;
              border-radius: 10px;
              -webkit-box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
              box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
              float: right;
              margin-top: 15px;
            "
            v-on:click="assignTag()"
          >
            Lưu
          </button>
        </div>
      </div>
    </div>

    <div class="modal" v-bind:class="{ 'modal-show': showModalCS }">
      <!-- Modal content -->
      <div class="modal-content">
        <span class="close" v-on:click="hideModalAssignCs()">&times;</span>
        <div>
          <h5>Gán CS cho hội thoại</h5>

          <multiselect
            v-model="cs"
            placeholder="Tìm kiếm"
            label="name"
            track-by="id"
            :options="pmUsers"
            :taggable="true"
          ></multiselect>

          <hr />
          <button
            style="
              line-height: 30px;
              padding: 5px;
              margin-right: 15px;
              text-decoration: none;
              background: #377dff;
              vertical-align: top;
              color: white;
              border-radius: 10px;
              -webkit-box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
              box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
              float: right;
              margin-top: 15px;
            "
            v-on:click="assignTagCS()"
          >
            Lưu
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Preview from "vue-media-preview";
import * as imageConversion from "image-conversion";
import firebase from "../../firebase";
import "firebase/compat/firestore";
import message from "../../mixins/message";
import ApiService from "@/service/api.service";

const db = firebase.firestore();
const axios = require("axios").default;
export default {
  name: "PageBlockChatCS",
  components: {
    Preview,
  },
  mixins: [message],
  props: {
    group: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      listMessage: [],
      prepUser: null,
      unsubscribe: null,
      groupImage: null,
      groupName: "Guest",
      uid: null,
      chatMembers: undefined,
      urlPreview: null,
      apiUploadMedia: process.env.VUE_APP_UPLOAD_STORAGE_URL,
      tokenUploadMedia: process.env.VUE_APP_PREP_TOKEN,
      paging: {
        message_per_page: 10,
        end: false,
        loading: false,
      },
      ref: {
        messages: null,
        messagesNext: null,
      },
      listMessageExam: [],
      isLoadMore: false,
      infoPreview: null,
      programme: "",
      showModal: false,
      showModalCS: false,
      pmUsers: [],
      cs: null,
    };
  },
  computed: {
    listUsers() {
      const uids = [];
      for (const key in this.group.members) {
        uids.push(key);
      }
      console.log(uids);
      return uids;
    },
    isThirdView() {
      const find = this.listUsers.findIndex(
        (user) => user == this.prepUser.uid
      );
      if (find != -1) {
        return false;
      }
      return true;
    },
  },
  methods: {
    getUserById(id) {
      let user = null;
      for (let i = 0; i < this.group.users.length; i++) {
        if (this.group.users[i].uid == id) {
          user = this.group.users[i];
        }
      }
      return user;
    },
    showModalAssignCs() {
      this.showModalCS = true;
    },
    hideModalAssignCs() {
      this.showModalCS = false;
    },
    showModalTag() {
      this.showModal = true;
    },
    hideModalTag() {
      this.showModal = false;
    },
    assignTag() {
      this.hideModalTag();
      this.$store.commit("prepChatGroup/updateGroupCsTags", {
        id: this.group.id,
        value: this.group.tags,
      });
      this.$emit("updateGroupCs", this.group);
    },
    async assignTagCS() {
      if (this.cs) {
        // group
        this.hideModalAssignCs();
        let user = await this.getInfoUserById(this.cs.id);
        this.$store.commit("prepChatGroup/updateGroupCsMember", {
          id: this.group.id,
          value: this.cs.id,
          user: user,
        });

        const index = this.$store.state.prepChatGroup.groupsManagerCs.findIndex(
          (x) => x.id === this.group.id
        );
        if (index != -1) {
          let newGroup = this.$store.state.prepChatGroup.groupsManagerCs[index];
          this.$emit("updateGroupCs", newGroup);
        }

        let vm = this;
        let csname = user.displayName;
        setTimeout(function () {
          let doc = document.getElementById(vm.group.id);
          doc.click();

          let div = doc.querySelector(".csname");
          if (div) {
            div.innerHTML = "";
            div.innerHTML = "CS: " + csname;
          }

          console.log(div);
        }, 1000);
      }
    },
    async getInfoUserById(id) {
      let data = null;
      await db
        .collection("users")
        .doc(String(id))
        .get()
        .then(function (doc) {
          if (doc.exists) {
            data = doc.data();
          }
        });
      return data;
    },
    mapsInfoPreview: function (g) {
      let that = this;
      if (g.users != null && g.users.length > 0) {
        for (let index = 0; index < g.users.length; index++) {
          const element = g.users[index];
          if (element.uid != that.prepUser.id) {
            that.infoPreview = {
              id: element.uid,
              phone: element.phone ?? "",
            };
          }
        }
      }
    },
    syncContentMessage: async function (groupId) {
      const that = this;
      that.unsubscribe = db
        .collection("messages")
        .doc(groupId.trim())
        .collection("messages")
        .orderBy("sentAt", "desc")
        .limit(that.paging.message_per_page)
        .onSnapshot((querySnapshot) => {
          const allMessages = [];
          querySnapshot.forEach((doc) => {
            if (doc) {
              let arr = doc.data();
              arr.id = doc.id;

              allMessages.push(arr);
            }
          });
          that.pushAfterMessage(allMessages);
        });
    },
    pushBeforeMessage(allMessages) {
      allMessages.sort(() => -1);
      for (let index = 0; index < allMessages.length; index++) {
        const exist = this.listMessage.findIndex(
          (x) => x.id === allMessages[index].id
        );
        if (exist == -1) {
          this.listMessage.unshift(allMessages[index]);
        }
      }
    },
    pushAfterMessage(allMessages) {
      allMessages.sort(() => -1);
      for (let index = 0; index < allMessages.length; index++) {
        const exist = this.listMessage.findIndex(
          (x) => x.id === allMessages[index].id
        );
        if (exist == -1) {
          this.listMessage.push(allMessages[index]);
        }
      }
    },
    async makeData() {
      this.prepUser = this.$store.state.prepChatUser.user;
      this.uid = this.$store.state.prepChatUser.user.uid;
      this.groupName = this.group.name;
      this.groupImage = require("../../assets/default-avatar.jpg");
      let arrayKey = Object.keys(await this.group.members);
      for (let i = 0; i < arrayKey.length; i++) {
        if (arrayKey[i] == String(this.group.createdBy)) {
          let m = await this.getInfoUserById(arrayKey[i]);
          if (m) {
            this.groupName = m.displayName;
            // this.group.name = m.displayName
            if (m.photoURL) this.groupImage = m.photoURL;
            // this.group.photoURL = m.photoURL
          }
        }
      }
    },
    sendMessage: function (box) {
      if (box.text) {
        const data = {
          id: box.id,
          message: box.text,
          type: box.contentType,
        };
        this.$emit("sendMessage", { data, box });
      }
      this.scrollBottomMessage();
      return (box.text = null);
    },
    sendMessageImage: function (url) {
      if (this.group) {
        const data = {
          id: this.group.id,
          message: url,
          type: "image",
        };
        let box = this.group;
        this.$emit("sendMessage", { data, box });
        this.scrollBottomMessage();
      }
    },
    sendMessageTagMarking: function (name, link) {
      if (this.group) {
        const marking = { name, link };
        const data = {
          id: this.group.id,
          message: JSON.stringify(marking),
          type: "tag-marking",
        };
        let box = this.group;
        this.$emit("sendMessage", { data, box });
        this.scrollBottomMessage();
      }
    },
    openSelectImage: function () {
      this.$refs.inputfilehidden.click();
    },
    async onFileChange(e) {
      let files = e.target.files;
      let that = this;
      let arrayImage = [];

      for (let index = 0; index < files.length; index++) {
        let res = await imageConversion.compressAccurately(files[index], 200);
        const myFile = new File([res], files[index].name, {
          type: res.type,
        });

        const headers = {
          "Content-Type": "application/json",
          token: that.tokenUploadMedia,
          "Access-Control-Allow-Origin": "*",
        };
        let formData = new FormData();
        formData.append("file", myFile);
        formData.append("directory", "chat");
        const response = await axios.post(that.apiUploadMedia, formData, {
          headers: headers,
        });
        if (response.status == "200") {
          arrayImage.push(response.data.url);
        }
      }
      that.sendMessageImage(arrayImage);
    },
    openPreview(url) {
      this.urlPreview = url;
    },
    hidePreview() {
      this.urlPreview = null;
    },
    tagMarkingName(data) {
      return JSON.parse(data).name;
    },
    tagMarkingLink(data) {
      let markingTicketId = JSON.parse(data).markingTicketId;
      return window.location.origin + "/marking-ticket/" + markingTicketId;
    },
    tagMarkingLesson(data) {
      return JSON.parse(data).lesson;
    },
    tagMarkingTicketID(data) {
      return JSON.parse(data).markingTicketId;
    },
    handleEventSendMessage: function (box, event) {
      if (event.keyCode == 13) {
        if (event.shiftKey) {
          return false;
        } else {
          this.sendMessage(box);
        }
      }
    },
    handleQuestions(ref) {
      this.listMessageExam = [];
      return new Promise((resolve) => {
        ref.get().then((documentSnapshots) => {
          /* If documentSnapshots is empty, then we have loaded all of pages */
          if (documentSnapshots.empty) {
            this.paging.end = true;
            resolve(documentSnapshots);
          }

          documentSnapshots.forEach((doc) => {
            let messData = doc.data();
            messData.id = doc.id;
            this.listMessageExam.push(messData);
          });

          if (this.isLoadMore) {
            this.pushBeforeMessage(this.listMessageExam);
          }

          /* Build reference for next page */
          const lastVisible =
            documentSnapshots.docs[documentSnapshots.size - 1];

          if (!lastVisible) {
            return;
          }

          this.ref.messagesNext = this.ref.messages
            .startAfter(lastVisible)
            .limit(this.paging.message_per_page);

          resolve(documentSnapshots);
        });
      });
    },
    loadMore() {
      this.isLoadMore = true;
      if (this.paging.end) {
        return;
      }

      this.paging.loading = true;
      this.handleQuestions(this.ref.messagesNext).then((documentSnapshots) => {
        this.paging.loading = false;

        if (documentSnapshots.empty) {
          /* If there is no more questions to load, set paging.end to true */
          this.paging.end = true;
        }
      });
    },
    scrollBottomMessage() {
      var container = this.$el.querySelector("#chat");
      container.scrollTop = container.scrollHeight;
    },
    closeGroup(id) {
      this.$emit("closeGroup", id);
    },
    async getAllpmUsers() {
      let vm = this;
      try {
        let url = "prep-app/user/all";
        let res = await ApiService.get(url);

        if (res.status == 200) {
          vm.pmUsers = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.syncContentMessage(this.group.id);
    /* Set common Firestore reference */
    this.ref.messages = db
      .collection("messages")
      .doc(this.group.id.trim())
      .collection("messages")
      .orderBy("sentAt", "desc");
    /* Load first page */
    const firstPage = this.ref.messages.limit(this.paging.message_per_page);
    this.handleQuestions(firstPage);

    this.makeData();
    this.mapsInfoPreview(this.group);
    this.getAllpmUsers();
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>
<style lang="scss">
.message-image img {
  width: 200px;
  height: 200px;
  margin: 0px 15px 5px 15px;
  border: 1px solid white;
  object-fit: cover;
}

.message-tag-marking {
  span {
    padding: 5px;
    // background: #FEF1D7;
    border-radius: 5px;
    color: black;
    margin-bottom: 5px;
  }

  p {
    margin-top: 5px;

    a {
      color: #377dff;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }
}

.block-chat-footer {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.html-textarea {
  border: none;
  display: block;
  width: 80%;
  height: 80px;
  border-radius: 3px;
  padding: 20px;
  font-size: 13px;
  margin-bottom: 0px;
  background: white;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.html-textarea::-webkit-scrollbar {
  display: none;
}

.modal-show {
  display: block !important;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 50%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  text-align: right;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
