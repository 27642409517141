<template>
  <div>
    <PageManagerCS />
  </div>
</template>

<script>
import PageManagerCS from "@/plugins/prepChat/components/cs/PageManagerCS.vue";
export default {
  components: {
    PageManagerCS,
  },
};
</script>

<style></style>
